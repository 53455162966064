<template>
  <!-- <v-form ref="form_title" v-model="valid"> -->
  <div>

    <v-row class="mt-0">
      <v-col cols="12" class="pb-0">
        <p class="primary--text">{{ $t('JOB.FIELD_TITLE') }} <br /><small class="grey--text numeric">REF: {{ref}}</small></p>
        <v-text-field
          v-model="title"
          @change="$emit('input', {title: $event})"
          required
          :rules="[$rules.maxlength(255)]"
          :placeholder="$t('JOB.FIELD_TITLE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="8" class="pb-0 pt-0">
        <v-text-field
          v-model="url"
          @change="$emit('input', {url: $event})"
          required
          :label="$t('JOB.FIELD_URL')"
          :placeholder="$t('JOB.FIELD_URL_PLACEHOLDER')"
          outlined
          dense
        >
          <template slot="append" v-if="url">
            <a :href="url" target="_blank"><v-icon size="24" class="pl-2">mdi-open-in-new</v-icon></a>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="4" class="pb-0 pt-0">
        <v-text-field
          v-model="date"
          @change="$emit('input', {date: $event})"
          required
          :label="$t('JOB.FIELD_DATE')"
          :placeholder="$t('JOB.FIELD_DATE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <p class="primary--text">{{ $t('JOB.CLASSIFICATION') }}</p>
    <v-row class="mt-0">
      <v-col cols="8" class="pt-0 pb-7">
        <v-combobox
          v-model="xtramile_occupation_id"
          :items="occupationItems"
          item-value="value"
          item-text="text"
          :label="$t('JOB.FIELD_OCCUPATION')"
          :placeholder="$t('JOB.FIELD_OCCUPATION_PLACEHOLDER')"
          clearable
          required
          hide-details
          outlined
          dense
          @change="$emit('input', {xtramile_occupation_id: $event ? $event.value : '' })"
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" class="pb-0 pt-0">
        <v-combobox
          v-model="location"
          no-filter
          :items="locationItems"
          :search-input.sync="locationInput"
          return-object
          @change="$emit('input', {location: $event})"
          required
          :label="$t('JOB.FIELD_LOCATION')"
          :placeholder="$t('JOB.FIELD_LOCATION_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="4" class="pt-0 pb-0">
        <p class="primary--text">{{ $t('JOB.CONTRACT') }}</p>
        <v-select
          v-model="contract_type"
          :items="contractTypeItems"
          @change="$emit('input', {contract_type: $event})"
          item-value="value"
          item-text="text"
          :label="$t('JOB.FIELD_CONTRACT_TYPE')"
          :placeholder="$t('JOB.FIELD_CONTRACT_TYPE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="4" class="pt-0 pb-0">
        <p>&nbsp;</p>
        <v-select
          v-model="contract_schedule"
          :items="contractScheduleItems"
          item-text="text"
          item-value="value"
          @change="$emit('input', {contract_schedule: $event})"
          required
          :label="$t('JOB.FIELD_CONTRACT_SCHEDULE')"
          :placeholder="$t('JOB.FIELD_CONTRACT_SCHEDULE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="4" class="pt-0 pb-0">
        <p>&nbsp;</p>
        <v-checkbox
        v-model="contract_remote"
        @change="$emit('input', {contract_remote: $event})"
        :label="$t('JOB.FIELD_CONTRACT_REMOTE')"
        outlined
        class="mt-0"
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12">
        <p class="primary--text">
          <span>{{ $t('JOB.FIELD_DESCRIPTION') }}</span>
          <v-btn
            small
            rounded
            depressed
            color="secondary black--text"
            class="ml-4 mr-4"
            @click="description_switch_html = !description_switch_html"
          >
            {{ $t(description_switch_html ? 'COMMON.SWITCH_HTML_OFF' : 'COMMON.SWITCH_HTML_ON') }}
          </v-btn>
        </p>

        <div>

          <div v-if="!description_switch_html">
            <v-textarea
              dense
              height="300px"
              v-model="description"
              @change="$emit('input', {description: $event})"
              :label="$t('JOB.FIELD_DESCRIPTION')"
              :placeholder="$t('JOB.FIELD_DESCRIPTION_PLACEHOLDER')"
              outlined
              required
            />
          </div>

          <div v-else  class="pl-4 pt-2 pb-2 pr-0 grey lighten-3 rounded html-content description-html-container">
            <div class="grey--text text--darken-2 scroll description-html pr-4" v-html="$showdown.makeHtml(description)"/>
          </div>

          <div v-if="!description_switch_html">
            <v-textarea
              dense
              height="150px"
              v-model="profile"
              @change="$emit('input', {profile: $event || ''})"
              :label="$t('JOB.FIELD_PROFILE')"
              :placeholder="$t('JOB.FIELD_PROFILE_PLACEHOLDER')"
              outlined
            />
          </div>
          <div v-else  class="pl-4 pt-2 pb-2 pr-0 grey lighten-3 rounded html-content profile-html-container">
            <div class="grey--text text--darken-2 scroll profile-html pr-4" v-html="$showdown.makeHtml(profile)"/>
          </div>

        </div>

        <div>
          <v-combobox
            dense
            v-model="tags"
            @change="$emit('input', {tags: $event})"
            :items="tagItems"
            :label="$t('JOB.FIELD_TAGS')"
            :placeholder="$t('JOB.FIELD_TAGS_PLACEHOLDER')"
            multiple
            small-chips
            outlined
            required
          />
        </div>

      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" class="pb-0 pt-0">
        <v-checkbox
          v-model="show_public"
          @change="$emit('input', {show_public: !!$event})"
          :label="$t('COMMON.SHOW_PUBLIC')"
          :placeholder="$t('COMMON.SHOW_PUBLIC')"
          outlined
          dense
        />
      </v-col>
    </v-row>

  </div>

  <!-- </v-form> -->
</template>

<script>
export default {
  name:"FormJobCompactIntro",
  data: (_this) => ({
    valid: false,
    occupationItems: _this.$i18n.t('JOB.XTRAMILE_OCCUPATIONS_ITEMS').sort((a, b) => a.text.localeCompare(b.text)),
    contractTypeItems: _this.$i18n.t('JOB.CONTRACT_TYPES_ITEMS'),
    contractScheduleItems: _this.$i18n.t('JOB.CONTRACT_SCHEDULES_ITEMS'),
    locationInput: '',
    locationItems: [],
    locationSearchTimeout: 0,
    locationLoading: false,
    parent_id: undefined,
    description_switch_html: true,
    tagItems: [],
    //
    ref: undefined,
    title: undefined,
    url: undefined,
    xtramile_occupation_id: undefined,
    date: undefined,
    location: undefined,
    contract_type: undefined,
    contract_schedule: undefined,
    contract_remote: undefined,
    description: undefined,
    profile: undefined,
    show_public: undefined,
    tags: []
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
    locationInput (n) {
      this.searchLocationItems(n)
    }
  },
  created () {
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'xtramile_occupation_id') {
          for (const occupation of  this.$i18n.t('JOB.XTRAMILE_OCCUPATIONS_ITEMS')) {
            if (values[key] === occupation.value) {
              values[key] = occupation
            }
          }
        }
        if (key === 'date' && typeof values[key] === 'string') values[key] = values[key].slice(0,10)
        this[key] = values[key]
      })
    },

    searchLocationItems (n, time = 200) {
      const then = (response) => {
        let data = []
        if (response && response.data) data = response.data.map(v => v.location)
        if (this.location) data = data.concat([this.location])
        this.locationItems = data
      }
      if (this.locationSearchTimeout) clearTimeout(this.locationSearchTimeout)
      this.locationLoading = true
      this.locationSearchTimeout = setTimeout(() => {
        if (n) {
          this.$services.api_programmatic
            .location.search({where: {city:n}, options: {limit: 10}})
            .then(then)
        }else {
          Promise.resolve().then(then)
        }
      }, +time || 0)
    },
  }
}
</script>
